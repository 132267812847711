export const Config = {
    api: {
        endPoint: "https://qa-myplatformapi.pinnacletech.com/api/"
    },
    auth0: {
        domain: "qa-signin.pinnacleauth.com",
        clientId: "Ti3omzlav4l6FDf0s5yGc7xy7QkaB0js",
        authorizationParams: {
            redirect_uri: 'https://qa-myplatform.pinnacletech.com/callback',
            audience: "https://pinnacleplatform.qa",
            scope: "email profile"
        }
    },
    FileConfig:{
        restrictions:{
            maxTotalFileSize: "1073741824",
            maxNumberOfFiles: "30",
        },
        fileManagerSelectionType: "both"
    },
    walkMeSrc: "https://cdn.walkme.com/users/6ac8332db83a4ea4b2d93b7b4009ae06/qa/walkme_6ac8332db83a4ea4b2d93b7b4009ae06_https.js",
    techSupport: {
        emailAddress: "support@pinnacletech.com"
    },
    projectQuestions: {
        toEmailAddress: "Platform@pinnaclereliability.com"
    },
    maxIdleTimeToLogout: {
        seconds: "43200"
    },
    signalR: {
        accessKey: "QklI3vUqXRYcObyFT9NDTmhZBmNyyqXZWG2L5BtetEQ="
    },
    GoogleAnalytics: {
        measurementId: "G-QWHJNS5K1B"
    },
    ZenDesk: {
        key: "10271455-5f8b-4aaa-8468-f96ae59dae41"
    },
    PinnacleUsersDomain : ["pinnacletech.com", "pinnaclereliability.com"],
    MUI: {
        licenseKey: "1b74c4c760060652cf0473609c64fe0eTz01MzUwMCxFPTE2OTg5MzExODA0MTksUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    }
};
