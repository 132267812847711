import * as React from 'react';
import './dashboardDocuments.scss';
import 'hammerjs';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import apiCalls from '../../utils/api_calls';
import { Config } from '../../config/config';
import { Toast } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import BackDrop from 'components/BackDrop';
import { GetProjectItemsListType } from 'types/CommonReducerType';
import { DASHBOARD_ITEM_LIMIT } from 'utils/utils';
import { Button } from '@mui/material';

type documentsProps = {
  details: any;
};

function DashboardDocuments(props: documentsProps) {
  const [show, setShow] = React.useState(true);
  const [projectDocuments, setProjectDocuments] = React.useState<any>([]);
  const [documentsCount, setDocumentsCount] = React.useState<number>(0);
  const [toastShowDownloadErr, setToastShowDownloadErr] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const navigate = useNavigate();
  const labelContent = (e: any) => e.category;

  React.useEffect(() => {
    if (props.details.id != null && props.details.id != '') {
      getProjectDocuments();
    }
  }, [props.details]);

  function getProjectDocuments() {
    setIsLoading(true);
    let getProjectDetailsRequestBody: GetProjectItemsListType = {
      ProjectId: !props.details.isGroupSelected ? props.details.id : '',
      GroupId: props.details.isGroupSelected ? props.details.id : '',
      ItemName: 'documents',
      IsHeaderRequired: false,
      Limit: DASHBOARD_ITEM_LIMIT
    };

    if (props.details.isGroupSelected) {
      apiCalls
        .create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
        .then(async (res: any) => {
          let groupDocs = res.length > 0 ? res : [];

          let projDocuments: any = [];

          groupDocs.forEach((group: any) => {
            projDocuments.push(...group.documents);
          });

          projDocuments = projDocuments.filter((x: any) => x.publishtoPlatform != 'Not Shared');
          projDocuments.forEach((e: any) => {
            e['modifiedBy'] = getDocModifiedBy(e.title, e.modifiedBy, e.platformModifiedBy, e.timeLastModified, e.platformTimeLastModified);
            e['timeLastModified'] = getDocModifiedOn(
              e.title,
              e.modifiedBy,
              e.platformModifiedBy,
              e.timeLastModified,
              e.platformTimeLastModified
            );
          });
          projDocuments = projDocuments.sort((a: any, b: any) => {
            return new Date(b.timeLastModified) > new Date(a.timeLastModified) ? 1 : -1;
          });
          setDocumentsCount(projDocuments.length);
          projDocuments = projDocuments.slice(0, 3);
          setProjectDocuments(projDocuments);
          setIsLoading(false);
        })
        .catch((err) => {
          setDocumentsCount(0);
          setProjectDocuments([]);
          setIsLoading(false);
        });
    } else {
      apiCalls
        .create(Config.api.endPoint + 'GetProjectItemsList', getProjectDetailsRequestBody)
        .then(async (res: any) => {
          let projDocuments: any = res.length > 0 ? res[0].documents : [];

          projDocuments = projDocuments.filter((x: any) => x.publishtoPlatform != 'Not Shared');
          projDocuments.forEach((e: any) => {
            e['modifiedBy'] = getDocModifiedBy(e.title, e.modifiedBy, e.platformModifiedBy, e.timeLastModified, e.platformTimeLastModified);
            e['timeLastModified'] = getDocModifiedOn(
              e.title,
              e.modifiedBy,
              e.platformModifiedBy,
              e.timeLastModified,
              e.platformTimeLastModified
            );
          });
          projDocuments = projDocuments.sort((a: any, b: any) => {
            return new Date(b.timeLastModified) > new Date(a.timeLastModified) ? 1 : -1;
          });
          setDocumentsCount(projDocuments.length);
          projDocuments = projDocuments.slice(0, 3);
          setProjectDocuments(projDocuments);
          setIsLoading(false);
        })
        .catch((err) => {
          setDocumentsCount(0);
          setProjectDocuments([]);
          setIsLoading(false);
        });
    }
  }

  function getDocModifiedBy(
    title: string,
    modifiedBy: string,
    platformModifiedBy: string,
    timeLastModifiedOn: string,
    platformTimeLastModifiedOn: string
  ) {
    var lastModifiedBy = null;
    var timeLastModified = timeLastModifiedOn ? new Date(timeLastModifiedOn) : null;
    var platformTimeLastModified = platformTimeLastModifiedOn ? new Date(platformTimeLastModifiedOn) : null;

    if (modifiedBy != null && platformModifiedBy == null) {
      lastModifiedBy = modifiedBy;
    } else if (modifiedBy == null && platformModifiedBy != null) {
      lastModifiedBy = platformModifiedBy;
    } else if (modifiedBy == null && platformModifiedBy == null) {
      lastModifiedBy = null;
    } else {
      if (timeLastModified != null && platformTimeLastModified != null) {
        if (timeLastModified > platformTimeLastModified) {
          lastModifiedBy = modifiedBy;
        } else {
          lastModifiedBy = platformModifiedBy;
        }
      }
    }
    return lastModifiedBy;
  }

  function getDocModifiedOn(
    title: string,
    modifiedBy: string,
    platformModifiedBy: string,
    timeLastModifiedOn: string,
    platformTimeLastModifiedOn: string
  ) {
    var lastModifiedOn = null;
    var timeLastModified = timeLastModifiedOn ? new Date(timeLastModifiedOn) : null;
    var platformTimeLastModified = platformTimeLastModifiedOn ? new Date(platformTimeLastModifiedOn) : null;

    if (modifiedBy != null && platformModifiedBy == null) {
      lastModifiedOn = timeLastModified;
    } else if (modifiedBy == null && platformModifiedBy != null) {
      lastModifiedOn = platformTimeLastModified;
    } else if (modifiedBy == null && platformModifiedBy == null) {
      lastModifiedOn = null;
    } else {
      if (timeLastModified != null && platformTimeLastModified != null) {
        if (timeLastModified > platformTimeLastModified) {
          lastModifiedOn = timeLastModified;
        } else {
          lastModifiedOn = platformTimeLastModified;
        }
      }
    }
    return lastModifiedOn;
  }

  function DownloadFile(docDetails: any) {
    var body = JSON.parse(
      JSON.stringify({
        projectId: docDetails.ProjectUUId,
        fileId: docDetails.id,
        fileName: docDetails.name,
        projectName: docDetails.ProjectSiteName
      })
    );
    apiCalls
      .downloadBlob(Config.api.endPoint + 'FileDownload', body)
      .then(async (res: any) => {
        var blob: any = res;
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', docDetails.name);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      })
      .catch((err) => {
        console.log('err', err);
        setToastShowDownloadErr(true);
        console.log('Failed to download file.');
      });
  }

  return (
    <div data-cy={'dashboard-documents-card'} className="card dashboard-documents-card">
      <Toast
        style={{
          position: 'fixed',
          top: '70px',
          right: 0,
          marginRight: '15px',
          height: '60px',
          zIndex: 9,
          backgroundColor: '#e74c3c'
        }}
        onClose={() => setToastShowDownloadErr(false)}
        show={toastShowDownloadErr}
        delay={3000}
        autohide
      >
        <Toast.Body className="text-white mt-1">
          <FontAwesomeIcon data-cy="users-create-info-icon" size="1x" icon={faExclamationCircle} /> &nbsp; Error occured while downloading
          the file.!
        </Toast.Body>
      </Toast>
      <div data-cy="card-title" className="regionDocumentsHeader">
        <div style={{ alignItems: 'center' }} className="row">
          <span className="actions-main-header col-md-8">Latest Files</span>
          <span className="col-md-4">
            <Button style={{ float: 'right' }} onClick={() => navigate('/project/files')} color="primary" >
              View All
            </Button>
          </span>
        </div>
      </div>

      <div className="dashboard-documents-div">
        <BackDrop isLoading={isLoading} />
        
          {projectDocuments.length > 0 && (
            <div className="dashboard-documents-items">
              <div data-cy={'documents-div'} style={{ fontSize: 12 }}>
                {projectDocuments?.map((x: any, index: number) => (
                  <div key={'Documents' + index}>
                    <div className="row">
                      <div className="col-sm-1">
                        <button
                          title="Export Excel"
                          style={{ marginTop: 2 }}
                          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                          onClick={() => DownloadFile(x)}
                        >
                          <FontAwesomeIcon style={{ marginTop: 3, marginBottom: 3 }} data-cy={x.id + '-download-icon'} icon={faDownload} />
                        </button>{' '}
                      </div>
                      <div className="col-sm-11 clipped">
                        <span className="documents-main-header">{x.title}</span>
                        <br />
                        <span className="documents-sub-header" style={{ fontSize: 11, color: 'gray' }}>
                          Modified by: {x.modifiedBy}
                        </span>{' '}
                        &nbsp;
                        <span className="documents-sub-header" style={{ fontSize: 11, color: 'gray' }}>
                          Modified on: {new Date(x.timeLastModified).toLocaleString()}
                        </span>
                        <br />
                        <hr className="line-separator" hidden={index + 1 === projectDocuments?.length} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {projectDocuments.length === 0 && <span>No documents available</span>}
      </div>
    </div>
  );
}
export default DashboardDocuments;
